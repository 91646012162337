import { theme } from "@chakra-ui/core"

const breakpoints = ["0px", "360px", "768px", "1024px", "1440px"]
breakpoints.st = breakpoints[0]
breakpoints.sm = breakpoints[1]
breakpoints.md = breakpoints[2]
breakpoints.lg = breakpoints[3]
breakpoints.xl = breakpoints[4]

// const newBreakPoints = {
//   ...theme.breakpoints,
//   ...{ st: "0px" },
// }

const newTheme = {
  ...theme,
  breakpoints,
}

export default newTheme
